

import { useRef, useState } from "react";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const ForgotPassword = ({ onHide, onClickLogin }) => {
  const formRef = useRef();
  const [error, setError] = useState(null);
  const sendResetPasswordInstuctions = async (userInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/password`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      onHide();
      MySwal.fire({
        title: data.message
      })
    } catch (error) {
      setError(error)
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { email: data.email }
    }
    sendResetPasswordInstuctions(userInfo)
  }
  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <p className="text-center">Enter your email address below to get reset password link.</p>
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-2">
          <label className="fw-medium">Email</label>
          <input type="email" name="email" className="form-control w-100" autoFocus={true} required={true} />
        </div>

        <div className="mt-2">
          {/* <button type="submit" className="btn btn-success btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative text-white fw-medium">Send me reset password instructions</button> */}
          <button type="submit" className="btn btn-success btn-outline-light border border-1 w-100 bg-orange position-relative text-white fw-medium">Send</button>
          <div className="mt-3 d-flex justify-content-end cursor-pointer">
            {/* <i><strong className="text-orange" onClick={onClickLogin}>Back to Signin</strong></i> */}
          </div>
        </div>
      </form>
    </>
  )
};

export default ForgotPassword;