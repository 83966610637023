
import { useRef, useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import google_icon from '../assets/images/google.svg';
import { useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';

const MySwal = withReactContent(Swal);

function Signup({ setCurrUser, setShow, onChangeContent, setTempUser, setAuthToken, authToken, onHide, showAlert, onClickLogin }) {
  const formRef = useRef()
  const [error, setError] = useState(null);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }).then((res) => {
          saveGoogleUser(res.data)
        }).catch((err) => { });
    },
    onError: (error) => { }
  });

  async function saveGoogleUser(userInfo) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/create-google-user`
    try {
      const response = await fetch(url, {
        method: 'post',
        headers: {
          "content-type": 'application/json',
          "accept": "application/json"
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setTempUser(data);
      setAuthToken(response.headers.get("Authorization"));
      if (data.phone == undefined || data.phone == '') {
        onChangeContent('verify_phone');
      } else {
        onChangeContent('verify_otp');
      }
      //localStorage.setItem("token", response.headers.get("Authorization"))
      //setCurrUser(data)
      // onHide();
    } catch (error) {
      // setError(error)
    }
  }

  const signup = async (userInfo, setCurrUser) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/signup`
    try {
      const response = await fetch(url, {
        method: 'post',
        headers: {
          "content-type": 'application/json',
          "accept": "application/json"
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      // localStorage.setItem('token', response.headers.get("Authorization"))
      // setCurrUser(data)
      onHide();
      MySwal.fire({
        title: 'An Email with Confirmation Link is Sent your email, please confirm '
      })
    } catch (error) {
      // setError(error)
    }
  }
  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { email: data.email, password: data.password, name: data.name }
    }
    signup(userInfo, setCurrUser)
    // e.target.reset()
  }
  const handleClick = e => {
    e.preventDefault()
    setShow(true)
  }

  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-2">
          <label className='fw-medium'>Name</label>
          <br />
          <input type='text' name='name' autoFocus={true} className='form-control w-100' />
          <span className="feedback"></span>
        </div>
        <div className="mt-2">
          <label className='fw-medium'>Email</label>
          <br />
          <input type='email' name='email' className='form-control w-100' />
          <span className="feedback"></span>
        </div>
        <div className="mt-2">
          <label className='fw-medium'>Password</label>
          <br />
          <input type='password' name='password' className='form-control w-100' />
          <span className="feedback"></span>
        </div>
        {/* <div className="text-center mt-3" style={{ color: '#505050', fontSize: '0.9375rem', fontWeight: 400, textAlign: 'center' }}>
          <p>By tapping "Agree & Sign up" you agree to OfferUp's Terms of Service and acknowledge the Privacy Policy.</p>
        </div> */}
        <div className="actions mt-3">
          {/* <button type="submit" className='btn btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative text-white fw-medium'>Create an account</button> */}
          {/* <button className="btn btn-primary bg-blue border border-1 w-100 text-orange rounded-pill position-relative mt-2" onClick={() => googleLogin()}>
            <img src={google_icon} className='position-absolute h-24 left-2 bg-white rounded-circle' alt='google icon' />
            <span className="text-orange text-white">Continue with Google</span>
          </button> */}
          <button type="submit" className='btn btn-outline-light border border-1 w-100 bg-orange position-relative text-white fw-medium'>Create an account</button>
          <button className="btn btn-default text-dark-grey border border-1 w-100 position-relative mt-3 d-flex justify-content-center gap-1 align-items-center mt-2" onClick={() => googleLogin()}>
            <img src={google_icon} className='h-24 left-2 bg-white rounded-circle' alt='google icon' />
            <span>Continue with Google</span>
          </button>
          <div className="mt-2 d-flex justify-content-center gap-1">
            <span>Already have an account?</span>
            <span className="text-orange cursor-pointer" onClick={onClickLogin}>Login</span>
          </div>
        </div>
      </form>
    </>
  );
}

export default Signup;