
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import rightArrow from "../../assets/images/right-arrow.svg"
import Footer from "../Footer";

const MySwal = withReactContent(Swal);

const ArchivedItems = () => {
  const [archivedProducts, setArchivedProducts] = useState([]);

  async function fetchArchivedProducts() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/archived-products`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setArchivedProducts(data)
    }
    catch (error) {
      setArchivedProducts([])
    }
  }

  useEffect(() => {
    fetchArchivedProducts()
  }, []);

  const archiveProduct = async (product_id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${product_id}/remove-from-archive`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) {
        throw data.error
      }
      const data = await response.json()
      if (data.status === 'removed') {
        let updated_products = archivedProducts.filter(p => p.id !== product_id)
        setArchivedProducts(updated_products)
        MySwal.fire({
          title: 'Product removed from Archived list'
        })
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error removing the product from Saved List'
      })
    }
  }

  return (
    <>
      <div className="container">
        <div className="row  mt-3">
          <div className="col-6">
            <h1>Archived Products</h1>
            <ul className="list-group list-group-flush">
              {
                archivedProducts.length === 0 ? <div><h2>No Products in Archived List</h2></div> : archivedProducts.map(product => {
                  return <li className="list-group-item d-flex justify-content-between align-items-center" key={product.id}>
                    <div className="d-flex align-items-center">
                      <Link to={`/product/${product.id}`}>
                        <img src={product.main_image} style={{ height: '50px', width: '50px' }} alt={"Main Imag"} />
                      </Link>
                      <div className="d-flex flex-column ms-3">
                        <Link to={`/product/${product.id}`}><strong className="underline fs-5">{product.title}</strong></Link>
                        <strong className="text-orange fs-6 cursor-pointer" onClick={() => archiveProduct(product.id)}>Unarchive</strong>
                      </div>
                    </div>
                    <Link to={`/product/${product.id}`}><img src={rightArrow} style={{ height: '16px', width: '16px' }} alt={"right arrow"} /></Link>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
};


export default ArchivedItems;