

// App.js
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from "./components/Home.jsx";
import SellItem from './components/Product/SellItem.jsx';
import Navbar from './components/Navbar.js'

import User from './components/User.js'
import NavBar from './components/Navbar.js';
import OfferupModal from './components/Modals/OfferupModal.jsx';
import SelectLogin from './components/SelectLogin.jsx';
import SignupLogin from './components/SignupLogin.jsx';
import Signup from './components/Signup.jsx';
import Login from './components/Login.jsx'
import Categories from './components/Categories.jsx';
import AccountSettings from './components/Product/User/AccountSettings.jsx';
import VerifyPhone from './components/Auth/VerifyPhone.jsx';
import VerifyOtp from './components/Auth/VerifyOtp.jsx';
import Confirmation from './components/Auth/Confirmation.jsx';
import ForgotPassword from './components/Auth/ForgotPassword.jsx';
import ResetPassword from './components/Auth/ResetPassword.jsx';
import ShowProduct from './components/Product/ShowProduct.jsx';
import Alert from './components/Modals/Alert.jsx'

import { useSearchParams } from 'react-router-dom';
import SavedProduct from './components/Product/SavedProduct.jsx';
import MyItems from './components/Product/MyItems.jsx';
import ArchivedItems from './components/Product/ArchivedItems.jsx';
import { useTranslation } from "react-i18next";
// import About from './Pages/About';
// import Products from './Pages/Products';

import ThemeContext from "./context/ThemeContext.jsx"
import UserProfile from './components/Product/User/UserProfile.jsx';
import NotificationSettings from './components/Product/User/NotificationSettings.jsx';
import Transactions from './components/Product/User/Transactions.jsx';
import Payments from './components/Product/User/Payments.jsx';
import Inbox from './components/Product/User/Inbox.jsx';
import Messages from './components/Product/User/Messages.jsx';
import AdminProducts from './components/Admin/ListProducts.jsx'
import AdminUsers from './components/Admin/ListUsers.jsx'
import AdminReports from "./components/Admin/Reports.jsx"
import EditProduct from './components/Admin/EditProduct.jsx';
import EditUser from './components/Admin/EditUser.jsx';


const App = () => {
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "bz" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  }

  const [theme, setTheme] = useState(localStorage.getItem('theme') || "light");
  const [searchParams, setSearchParams] = useSearchParams();
  let search = searchParams.get('search');

  const [currUser, setCurrUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState('selectLogin');
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [city, setCity] = useState('');
  const [alert, showAlert] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [tempUser, setTempUser] = useState(null);

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [error, setError] = useState(null);

  const min = searchParams.get('min');
  const max = searchParams.get('max');
  const condition = searchParams.get('condition');
  const category_id = searchParams.get('category_id');
  const sub_category_id = searchParams.get('sub_category_id');

  let showGobackBtn = false;
  let goBackContent = '';
  let modalContent = '';
  let modalTitle = '';
  if (content === 'selectLogin') {
    modalTitle = 'Sign up / Log in';
    modalContent = <SelectLogin currUser={currUser} setCurrUser={setCurrUser} onHide={hideModal} onChangeContent={handleSetContent} />
  } else if (content === 'signupLogin') {
    modalTitle = 'Sign up / Log in';
    modalContent = <SignupLogin onChangeContent={handleSetContent} />
  } else if (content === 'signup') {
    // goBackContent = 'signupLogin';
    // showGobackBtn = true;
    modalTitle = 'Create an account';
    modalContent = <Signup currUser={currUser} setCurrUser={setCurrUser} setTempUser={setTempUser} setAuthToken={setAuthToken} authToken={authToken} onHide={hideModal} onClickLogin={() => setContent('login')} onChangeContent={handleSetContent} />
  } else if (content === 'login') {
    // showGobackBtn = true;
    // goBackContent = 'signupLogin';
    modalTitle = 'Log in';
    modalContent = <Login currUser={currUser} setCurrUser={setCurrUser} setTempUser={setTempUser} setAuthToken={setAuthToken} onClickSingUp={() => setContent('signup')} onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === 'verify_phone') {
    showGobackBtn = false;
    goBackContent = 'login';
    modalTitle = 'Verify Phone';
    modalContent = <VerifyPhone currUser={currUser} setCurrUser={setCurrUser} tempUser={tempUser} setTempUser={setTempUser} authToken={authToken} onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === 'verify_otp') {
    showGobackBtn = false;
    goBackContent = 'login';
    modalTitle = 'Code Validation';
    modalContent = <VerifyOtp currUser={currUser} setCurrUser={setCurrUser} tempUser={tempUser} setTempUser={setTempUser} authToken={authToken} onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === 'forgot_password') {
    showGobackBtn = true;
    goBackContent = 'login';
    modalTitle = 'Forgot Password';
    modalContent = <ForgotPassword currUser={currUser} setCurrUser={setCurrUser} onClickLogin={() => setContent('login')} onChangeContent={handleSetContent} onHide={hideModal} />
  }

  function showSignupModal() {
    setContent('signup');
    setShowModal(true);
  }

  function showLoginModal() {
    setContent('login');
    setShowModal(true);
  }

  const fetchNearestProducts = async (latitude, longitude) => {
    try {
      let queryStr = `?min=${min}&max=${max}&condition=${condition}&lat=${latitude}&lng=${longitude}&search=${search}&category_id=${category_id}&sub_category_id=${sub_category_id}&distance=${localStorage.getItem('distance')}&page=${page}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products${queryStr}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          // "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()

      setProducts((prevProducts) => [...prevProducts, ...data.products]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(data.current_page < data.total_pages);
    }
    catch (error) {
      setProducts([])
    }
  }

  // const getLatLngProducts = () => {
  //   const fetchNearestProducts = async (latitude, longitude) => {
  //     try {
  //       let queryStr = `?min=${min}&max=${max}&condition=${condition}&lat=${latitude}&lng=${longitude}&search=${searchValue}&page=${page}`;
  //       const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products${queryStr}`, {
  //         method: "get",
  //         headers: {
  //           "content-type": "application/json",
  //           // "authorization": localStorage.getItem("token")
  //         }
  //       })
  //       if (!response.ok) throw Error
  //       const data = await response.json()
  //       // setProducts(data)
  //       setProducts((prevProducts) => [...prevProducts, ...data.products]);
  //       setPage((prevPage) => prevPage + 1);
  //       setHasMore(data.current_page < data.total_pages);
  //     }
  //     catch (error) {
  //       setProducts([])
  //     }
  //   }

  //   const getCurrentLocation = () => {
  //     fetchNearestProducts(lat, lng)
  //     // showLoader(true)
  //     // let lat = localStorage.getItem("lat");
  //     // let lng = localStorage.getItem("lng");
  //     //if (lat != null && lng != null) {
  //     //  fetchNearestProducts(lat, lng);
  //     //} else {
  //     //  fetchNearestProducts()
  //     // if (navigator.geolocation) {
  //     //   navigator.geolocation.getCurrentPosition(
  //     //     (position) => {
  //     //       const latitude = position.coords.latitude;
  //     //       const longitude = position.coords.longitude;
  //     //       localStorage.setItem("lat", latitude);
  //     //       localStorage.setItem("lng", longitude);
  //     //       // setCurrentLocation({ latitude, longitude });
  //     //       fetchNearestProducts(latitude, longitude);
  //     //     },
  //     //     (error) => {
  //     //     }
  //     //   );
  //     // } else {
  //     // }
  //     //}
  //   }

  //   // getCurrentLocation();
  //   // setTimeout(() => {
  //   //   fetchNearestProducts(lat, lng);
  //   // }, 200);
  // }

  async function fetchCategories() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/categories`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          // "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setCategories(data)
    }
    catch (error) {
      setCategories([])
    }
  }

  useEffect(() => {
    theme === "dark" ?
      (
        document.body.classList.add("bg-dark", "text-light")
      ) :
      document.body.classList.remove("bg-dark", "text-light")
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== undefined) {
      getText();
    }
    fetchCategories();
    getLatLngLocation();
  }, [theme])

  const getLatLngLocation = () => {
    const fetchNearestAddress = async (latitude, longitude) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-lat-lng?lat=${latitude}&lng=${longitude}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        // setLat(latitude);
        // setLng(longitude);
        setCity(data.cidade.nome)
      } catch (error) {
        setError(error.message);
      }
    }

    const getCurrentLocation = () => {
      if (localStorage.getItem("lat") != undefined && localStorage.getItem("lng") != undefined) {
        let lat = localStorage.getItem("lat");
        let lng = localStorage.getItem("lng");
        setLat(lat);
        setLng(lng);
        fetchNearestAddress(lat, lng);
        fetchNearestProducts(lat, lng);
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              localStorage.setItem("lat", latitude);
              localStorage.setItem("lng", longitude);
              setLat(latitude);
              setLng(longitude);
              fetchNearestAddress(latitude, longitude);
              fetchNearestProducts(latitude, longitude);
            },
            (error) => {
              setError(error.message);
            }
          );
        } else {
          setError('Geolocation is not supported by this browser.');
        }
      }
    }
    getCurrentLocation();
  }

  const getText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/get-user`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setCurrUser(data)
    }
    catch (error) {
    }
  }

  function openModal() {
    setShowModal(true);
  }

  function hideModal() {
    setShowModal(false);
    setContent('selectLogin');
  }

  function handleSetContent(content) {
    setContent(content);
  }

  function displayAlert(show, type, message) {

  }



  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {showModal &&
        <OfferupModal
          showGoBack={showGobackBtn}
          show={showModal}
          hide={hideModal}
          title={modalTitle}
          goBackContent={goBackContent}
          onChangeContent={handleSetContent}
        >
          {modalContent}
        </OfferupModal>
      }

      <Navbar currUser={currUser} categories={categories} onClickLogin={showLoginModal} onClickSignUp={showSignupModal} cityName={city} setCity={setCity} lat={lat} lng={lng} setLat={setLat} setLng={setLng} searchValue={search} switchLanguage={handleChangeLanguage} setProducts={setProducts} />
      <Categories categories={categories} />
      {showAlert && <Alert />}
      <Routes>
        <Route path="/sell-item" element={<SellItem />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/notification-settings" element={<NotificationSettings />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/accounts/transactions" element={<Transactions />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/accounts/payments" element={<Payments />} />
        <Route path="/inbox/:id/messages" element={<Messages currUser={currUser} />} />
        {/* <Route path="/auth/google/callback" element={<Confirmation />} /> */}
        <Route path="/password/edit" element={<ResetPassword />} />
        <Route path="/product/:id" element={<ShowProduct currentUser={currUser} />} />
        <Route path="/saved_products" element={<SavedProduct />} />
        <Route path="/my-items" element={<MyItems />} />
        <Route path="/archived-products" element={<ArchivedItems />} />
        <Route path="/user/:id" element={<UserProfile />} />
        <Route path="/admin/products" element={<AdminProducts />} />
        <Route path="/admin/products/:id/edit" element={<EditProduct />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/reports" element={<AdminReports />} />
        <Route path="/admin/users/:id/edit" element={<EditUser />} />
        <Route path="/" element={<Home searchValue={search}
          lat={lat}
          lng={lng}
          timestamp={Date.now()}
          products={products}
          getLatLngProducts={() => fetchNearestProducts(lat, lng)}
          min={min}
          max={max}
          hasMore={hasMore}
        />} />
      </Routes>
    </ThemeContext.Provider>
  );
};

export default App;