
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import InfiniteScroll from 'react-infinite-scroll-component';

import React from 'react';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import Conditions from "./conditions";
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import avatar from '../../../assets/images/user_avatar.svg'
import outlineStar from '../../../assets/images/star-outline.svg'
import messageIcon from '../../../assets/images/message.svg'
import phoneIcon from '../../../assets/images/phone.svg'
import facebookIcon from '../../../assets/images/facebook.svg'
import emailIcon from '../../../assets/images/email-icon.svg'
import star from '../../../assets/images/star-grey.svg'
import starGolden from '../../../assets/images/star-golden.svg'
import Footer from '../../Footer';
// import outlineStar from '../../assets/images/star-outline.svg'


// import heartIcon from '../../../src/assets/images/heart.svg';
// import heartFilledIcon from '../../../src/assets/images/heart-filled.svg';
// import shareIcon from '../../../src/assets/images/share.svg';
// import shareIconBlack from '../../../src/assets/images/share-black.svg';
// import heartIconBlack from '../../../src/assets/images/heart-black.svg';
// import flagIconBlack from '../../../src/assets/images/flag-black.svg';

const MySwal = withReactContent(Swal);

const UserProfile = () => {
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [saved, setIsSaved] = useState(false);
  const [ratingScore, setRatingScore] = useState(0);
  const [ratings, setRatings] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const { id } = useParams();

  const getUser = async (userId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/show-user`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setUser(data);
      setRatingScore(data.rating_score);
      setRatings(data.ratings);
    } catch (error) {
      //  setError(error)
    }
  }

  const copyProductLink = async (productId) => {
    try {
      await navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/product/${productId}`);
      MySwal.fire({
        title: 'Link Copied to clipboard!!!'
      })
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }


  const saveProduct = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${user.id}/add-to-favourite`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setIsSaved(data.status === 'added')
    } catch (error) {
      //  setError(error)
    }
  }

  // const incrementProductViewCount=async (product_id)=> {
  //   const url=`${process.env.REACT_APP_BACKEND_URL}/products/${product_id}/increment-product-view-count`
  //   try{
  //       const response=await fetch(url, {
  //           method: "get",
  //           headers: {
  //               'content-type': 'application/json',
  //               'accept': 'application/json',
  //           }
  //       })
  //       const data=await response.json()
  //       if(!response.ok){
  //         setError(data.message)
  //         throw data.error
  //       }    
  //       setIsSaved(data.status === 'added')
  //   }catch(error){
  //     //  setError(error)
  //   }
  // }



  useEffect(() => {
    getUser(id);
    // incrementProductViewCount(id);
  }, [id]);

  async function fetchRatings() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${id}/fetch-ratings?page=${page}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          // "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()

      setRatings((prevRatings) => [...prevRatings, ...data.ratings]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(data.current_page < data.total_pages);
    }
    catch (error) {
      setRatings([])
    }
  }

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="d-flex">
              <img src={user && user.user_avatar_url ? user.user_avatar_url : avatar} alt={user?.title} style={{ height: '100px', width: '100px', borderRadius: '50%' }} />
              <div className='ms-3'>
                <strong>{user && user.name}</strong>
                <br />
                <span>Joined on {user && user.joined_on}</span>
                <br />
                {
                  user && user.location ?
                    <div>
                      <span>{user && user.location}</span>
                      <br />
                    </div> : ''
                }
                <span className='d-flex align-items-center gap-1'>
                  <img src={ratingScore.score >= 1 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                  <img src={ratingScore.score >= 2 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                  <img src={ratingScore.score >= 3 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                  <img src={ratingScore.score >= 4 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                  <img src={ratingScore.score >= 5 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                  <span className='fw-bold fs-5'>({ratingScore.length})</span>
                </span>
              </div>
            </div>
            <div className='d-flex justify-content-between mt-3'>
              <div className='text-center'>
                <strong>0</strong>
                <br />
                <span>Bought</span>
              </div>
              <div className='text-center'>
                <strong>{user?.sold}</strong>
                <br />
                <span>Sold</span>
              </div>
              <div className='text-center'>
                <strong>0</strong>
                <br />
                <span>Followers</span>
              </div>
            </div>
            <hr className='d-block d-lg-none' style={{ margin: '20px 0px' }} />
          </div>
          <div className='col-12 col-lg-8'>
            {/* <strong>Responds in few minutes</strong> */}
            <div className='d-flex mt-3' style={{ gap: '20px' }}>
              {/* <div className='d-flex flex-column align-items-center'>
                <div className='d-flex align-items-center bg-orange' style={{ borderRadius: "50%", padding: '7px' }}>
                  <img src={messageIcon} alt='message icon' style={{ height: '20px', width: '20px' }} />
                </div>
                <span>0% reply rate</span>
              </div> */}
              <div className='d-flex flex-column align-items-center'>
                <div className='d-flex align-items-center bg-orange' style={{ borderRadius: "50%", padding: '7px' }}>
                  <img src={phoneIcon} alt='phone icon' style={{ height: '20px', width: '20px' }} />
                </div>
                <span className='text-center'>{user && user.phone ? 'Confirmed Phone' : 'Phone not confirmed'}</span>
              </div>
              <div className='d-flex flex-column align-items-center'>
                <div className='d-flex align-items-center bg-orange' style={{ borderRadius: "50%", padding: '7px' }}>
                  <img src={facebookIcon} alt='message icon' style={{ height: '20px', width: '20px' }} />
                </div>
                <span className='text-center'>{user && user.proider === "facebook" ? "Confirmed Facebook" : "Facebook not confirmed"}</span>
              </div>
              <div className='d-flex flex-column align-items-center'>
                <div className='d-flex align-items-center bg-orange' style={{ borderRadius: "50%", padding: '7px' }}>
                  <img src={emailIcon} alt='email icon' style={{ height: '20px', width: '20px' }} />
                </div>
                <span className='text-center'>{user && user.confirmed ? "Email confirmed" : "Email not confirmed"}</span>
              </div>
            </div>
            <hr className='d-none d-lg-block' style={{ margin: '20px 0px' }} />
            {/* <h2>Compliments</h2>
          <div className="d-flex gap-4">
            <div className='d-flex gap-2 align-items-center'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Items as described</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Friendly</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>On time</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Reliable</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Communicative</span>
            </div>
          </div> */}
          </div>
        </div>
        <hr style={{ margin: '20px 0px' }} />
        <div className='row'>
          <div>
            <h4 style={{ fontWeight: '800' }}>Items from the seller</h4>
            <div className='products-list'>
              {
                user && user.products.map(sp => {
                  let [id, url, title, price] = sp
                  // return <Link to={`/product/${id}`} >
                  //   <img src={url} alt={sp.title} style={{ height: '100px', width: '100px' }} />
                  //   <br />
                  //   <strong>{title}</strong>
                  //   <br />
                  //   <span>${price}</span>
                  // </Link>
                  return (
                    <div className="product-item" key={id}>
                      <div style={{ height: 'calc(100% - 8px)' }}>
                        <Link to={`/product/${id}`} style={{ textDecoration: 'none' }}>
                          <div className="card product-shadow p-2 border-0" style={{ height: '100%' }}>
                            <div className="" style={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
                              <img src={url} className="" alt={title} style={{ maxWidth: '100%', height: '100%', position: 'absolute', objectFit: 'contain', top: '0px' }} />
                            </div>
                            <div className="mx-2">
                              <p className="my-1 fs-5" style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: 'none' }}>{title}</p>
                              <span className="fs-5 text-dark-orange" style={{ textDecoration: 'none' }}>${price}</span>
                              {/* <br />
                  <strong style={{ color: "rgb(164 164 164)" }}>{product.city}</strong> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <br />

        <div className='row mt-5'>
          <InfiniteScroll
            dataLength={ratings.length}
            next={fetchRatings}
            hasMore={hasMore}
            loader={''}
            // endMessage={<p>End of Products</p>}
            className={"row"}
          >
            {
              <div>
                <h4 style={{ fontWeight: '800' }}>Reviews</h4>
                <div className='gap-4'>
                  {
                    ratings && ratings.map(rate => {
                      return <>
                        <div className='d-flex gap-2'>
                          <strong>{rate.user_name}</strong>
                          <span className='d-flex align-items-center gap-1'>
                            <img src={rate.rating >= 1 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                            <img src={rate.rating >= 2 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                            <img src={rate.rating >= 3 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                            <img src={rate.rating >= 4 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                            <img src={rate.rating >= 5 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                          </span>
                        </div>
                        <div className='mt-1'>{rate.feedback}</div>
                        <hr />
                      </>
                    })
                  }
                </div>
              </div>
            }
          </InfiniteScroll>
        </div>
      </div >
      <Footer />
    </>
  )
};

export default UserProfile;