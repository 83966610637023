
import { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import { useSearchParams } from 'react-router-dom';

const Categories = ({ categories }) => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [params, setParams] = useSearchParams();

  function searchByCategory(category_id, sub_category_id) {
    let cParams = []
    if (params.get("min")) {
      cParams.push({ key: "min", value: params.get("min") })
    }
    if (params.get("max")) {
      cParams.push({ key: "max", value: params.get("max") })
    }
    if (params.get("condition")) {
      cParams.push({ key: "condition", value: params.get("condition") })
    }
    cParams.push({ key: "category_id", value: category_id })
    cParams.push({ key: "sub_category_id", value: sub_category_id })
    let paramsObj = {};
    cParams.forEach(x => paramsObj[x.key] = x.value)
    setParams(paramsObj)

    window.location.reload();
  }


  return (
    <div className="categories" style={{ backgroundColor: "#e1a559" }}>
      <div className={`d-flex align-items-start justify-content-between`}>
        {categories && categories.map((category) => {
          return (
            <div style={{ maxWidth: '224px' }} className="offer-body-2" key={category.id}>
              <div onClick={() => searchByCategory(category.id, '')} className="py-8 px-2 text-white" style={{ fontSize: '14px' }}>
                <span>{category.name}</span>
              </div>
              <div className="list">
                <div className={`${theme === "dark" ? "bg-dark text-light" : ""}`} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', gap: '5px', padding: '0px 5px' }}>
                  {category &&
                    category.sub_categories.map((sub_category) => {
                      return (
                        <div onClick={() => searchByCategory(category.id, sub_category.id)} className="item" key={sub_category.id}>
                          {sub_category.name}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          )
        })
        }
      </div>
    </div >
  )
};


export default Categories;