
import offerup_img from '../assets/images/offerup.svg';

function SignupLogin({ show, hide, onChangeContent }) {
  return (
    <>
      <div className="d-flex justify-content-center">
        <img src={offerup_img} alt='offerup logo' />
      </div>
      <div className="mt-4">
        <button className="btn btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative" onClick={() => onChangeContent('signup')}>
          <span className="text-white fw-medium">Sign up</span>
        </button>

        <button className="btn btn-outline-light border border-1 w-100 text-orange rounded-pill position-relative mt-2" onClick={() => onChangeContent('login')}>
          <span className="text-orange fw-medium">Log in</span>
        </button>

        <div className="text-center mt-3" style={{ color: "#505050", fontSize: '0.9375rem', fontWeight: 400, textAlign: 'center' }}>
          <p>This site is protected by reCAPTCHA and the Google Privacy Policy and the Google Terms of Service apply.</p>
        </div>
      </div>
    </>
  );
}

export default SignupLogin;