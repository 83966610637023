
import { useEffect, useState, useRef } from "react";
import { Link, redirect } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import Charts from "./../Charts/Chart"
import { render } from "@testing-library/react";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const MySwal = withReactContent(Swal);

const Reports = () => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    getProductsCreated(start, end);
    getUsersJoined(start, end);
  };

  function subDays(date, days) {
    const newDate = new Date(date);  // Clone the date to avoid mutating the original date
    newDate.setDate(newDate.getDate() - days);  // Add days
    return newDate;
  }

  const [productsCreated, setProductsCreated] = useState([]);
  const [usersCreated, setUsersCreated] = useState([]);
  const [productsSold, setProductsSold] = useState([]);

  async function getProductsCreated(sDate, eDate) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/admin/products/products-created?start_date=${sDate}&end_date=${eDate}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setProductsCreated(data.data)
    } catch (error) {
      //  setError(error)
    }
  }

  async function getUsersJoined(sDate, eDate) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/admin/users/users-joined?start_date=${sDate}&end_date=${eDate}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setUsersCreated(data.data)
    } catch (error) {
      //  setError(error)
    }
  }

  useEffect(() => {
    getProductsCreated(startDate, endDate);
    getUsersJoined(startDate, endDate);
  }, []);

  async function deleteUser(id) {
    if (!window.confirm('Are you sure you want to delete ?')) {
      return;
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/admin/users/${id}`
    try {
      const response = await fetch(url, {
        method: "delete",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()

      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
    } catch (error) {
      //  setError(error)
    }
  }

  const handleSelect = (ranges) => {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }



  return (
    <>
      <div className="mx-3">
        <div className="d-flex gap-2 justify-content-center mt-3">
          <Link to={"/admin/products"}>
            <button className="btn btn-sm btn-primary">Manage Products</button>
          </Link>
          <Link to={"/admin/users"}>
            <button button className="btn btn-sm btn-primary">Manage Users</button>
          </Link>
          <Link to={"/admin/reports"}>
            <button className="btn btn-sm btn-primary">Reports</button>
          </Link>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
        <h1>Products Posted</h1>
        <Charts dates={productsCreated.dates || []} counts={productsCreated.product_counts || []} />
        <br />
        <h1>Users Joined</h1>
        <Charts dates={usersCreated.dates || []} counts={usersCreated.user_joined || []} />
      </div >
    </>
  )
}

export default Reports; 