
import { useRef, useState } from 'react';
import offerup from './../../assets/images/offerup.svg'

const VerifyPhone = function ({ show, authToken, setCurrUser, tempUser, setTempUser, hide, onChangeContent, onHide }) {
  const formRef = useRef();
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { phone: data.phone }
    }
    sendCode(userInfo)
    // login(userInfo, setCurrUser)
    // e.target.reset()
  }

  function handleSkip(event) {
    event.preventDefault();
    skipPhone();
  }

  const skipPhone = async (userInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/skip-phone`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          "authorization": authToken || localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setCurrUser(tempUser);
      setTempUser(null);
      localStorage.setItem("token", authToken || localStorage.getItem("token"));
      onHide();
    } catch (error) {
      setError(error)
    }
  }

  const sendCode = async (userInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/send-otp`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          "authorization": authToken || localStorage.getItem("token")
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      // localStorage.setItem("token", response.headers.get("Authorization"))
      // setCurrUser(data)
      // setCurrUser(tempUser);
      // setTempUser(null);
      // localStorage.setItem("token", authToken || localStorage.getItem("token"));
      onChangeContent('verify_otp')
      // if (data.phone === "" || data.phone == null) {
      // } else {
      //   onHide();
      // }
    } catch (error) {
      setError(error)
    }
  }

  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <form ref={formRef}>
        {/* <div className="d-flex justify-content-center dddd">
          <img src={offerup} alt='offerup logo' />
        </div> */}
        <div className="mt-4">
          <div className="mt-2">
            <label className="fw-medium">Phone(e.g: +5511912345678)</label>
            <input type="text" name="phone" className="form-control w-100" autoFocus={true} required />
          </div>

          <div class="actions mt-2 gap-3 d-flex">
            {/* <button type="button" className="btn btn-outline-light border border-1 w-100 text-orange rounded-pill" onClick={handleSkip} >Skip</button> */}
            <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative text-white fw-medium" onClick={handleSubmit}>Send Code</button>
          </div>
        </div>
      </form>
    </>
  )
}

export default VerifyPhone;