
import { useRef, useState } from "react"
import google_icon from '../assets/images/google.svg';
import { useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';

function Login({ currUser, setCurrUser, setAuthToken, setTempUser, onHide, onChangeContent, onClickSingUp }) {
  const formRef = useRef();
  const [error, setError] = useState(null);

  const login = async (userInfo, setCurrUser) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/login`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      //localStorage.setItem("token", response.headers.get("Authorization"))
      //setCurrUser(data)
      setAuthToken(response.headers.get("Authorization"));
      setTempUser(data);
      if (data.phone == undefined || data.phone == '') {
        onChangeContent('verify_phone');
      } else {
        onChangeContent('verify_otp');
      }
    } catch (error) {
      setError(error)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { email: data.email, password: data.password }
    }
    login(userInfo, setCurrUser)
    e.target.reset()
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }).then((res) => {
          saveGoogleUser(res.data)
        }).catch((err) => { });
    },
    onError: (error) => { }
  });

  async function saveGoogleUser(userInfo) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/create-google-user`
    try {
      const response = await fetch(url, {
        method: 'post',
        headers: {
          "content-type": 'application/json',
          "accept": "application/json"
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      //localStorage.setItem("token", response.headers.get("Authorization"))
      //setCurrUser(data)
      setTempUser(data);
      setAuthToken(response.headers.get("Authorization"));
      if (data.phone == undefined || data.phone == '') {
        onChangeContent('verify_phone');
      } else {
        onChangeContent('verify_otp');
      }
      // onHide();
    } catch (error) {
      // setError(error)
    }
  }

  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-2">
          <label className="fw-medium">Email</label>
          <input type="email" name="email" className="form-control w-100" />
          <span className="feedback"></span>
        </div>

        <div className="mt-2">
          <label className="fw-medium">Password</label>
          <input type="password" name="password" className="form-control w-100" />
          <span className="feedback"></span>
        </div>

        <div>
          <span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onChangeContent('forgot_password')}>Forgot Password?</span>
        </div>

        {/* <div className="text-center mt-3" style={{ color: '#505050', fontSize: '0.9375rem', 'fontWeight': 400, textAlign: 'center' }}>
          <p>By tapping "Agree & Log in" you agree to OfferUp's Terms of Service and acknowledge the Privacy Policy.</p>
        </div> */}

        <div className="actions mt-3">
          {/* <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative text-white fw-medium">Agree & Log in</button> */}
          <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-orange position-relative text-white fw-medium">Login</button>
          <button type="button" className="btn btn-default text-dark-grey border border-1 w-100 position-relative mt-3 d-flex justify-content-center gap-1 align-items-center mt-2" onClick={() => googleLogin()}>
            <img src={google_icon} className='h-24 left-2 bg-white rounded-circle' alt='google icon' />
            <span>Continue with Google</span>
          </button>
          <div className="mt-2 d-flex justify-content-center gap-1">
            <span>Don't have an account?</span>
            <span className="text-orange cursor-pointer" onClick={onClickSingUp}>Singup</span>
          </div>
        </div>
      </form>
    </>
  );
}

export default Login;