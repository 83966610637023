
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import rightArrow from "../../assets/images/right-arrow.svg"
import Footer from "../Footer";

const MySwal = withReactContent(Swal);

const SavedProduct = () => {
  const [savedProducts, setSavedProducts] = useState([]);

  async function fetchSavedProducts() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/saved_products`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setSavedProducts(data)
    }
    catch (error) {
      setSavedProducts([])
    }
  }

  useEffect(() => {
    fetchSavedProducts()
  }, []);

  const saveProduct = async (product_id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${product_id}/add-to-favourite`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) {
        throw data.error
      }
      const data = await response.json()
      if (data.status === 'removed') {
        let updated_products = savedProducts.filter(p => p.product_id !== product_id)
        setSavedProducts(updated_products)
        MySwal.fire({
          title: 'Product Removed from Saved List'
        })
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error removing the product from Saved List'
      })
    }
  }

  return (
    <>
      <div className="container">
        <div className="row  mt-3">
          <div className="col-6 offset-3">
            <h1>Saved Products</h1>
            <ul className="list-group list-group-flush">
              {
                savedProducts.length == 0 ? <div><h2>No Products in Saved List</h2></div> : savedProducts.map(product => {
                  return <li className="list-group-item d-flex justify-content-between align-items-center saved-item ps-0">
                    <div className="d-flex align-items-center">
                      <Link to={`/product/${product.product_id}`}>
                        <img src={product.main_image} style={{ height: '50px', width: '50px' }} />
                      </Link>
                      <div class="d-flex flex-column ms-3">
                        <Link to={`/product/${product.product_id}`}><strong className="underline fs-5">{product.title}</strong></Link>
                        <strong className="text-orange fs-6 cursor-pointer delete-link" onClick={() => saveProduct(product.product_id)}>Delete</strong>
                      </div>
                    </div>
                    <Link to={`/product/${product.product_id}`}><img src={rightArrow} style={{ height: '16px', width: '16px' }} /></Link>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
      </div>
      <Footer classNames={'footer'} />
    </>
  )
};


export default SavedProduct;