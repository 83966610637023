

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import rightArrow from "../../assets/images/right-arrow.svg"
import Footer from "../Footer";

const MySwal = withReactContent(Swal);

const MyItems = () => {
  const [myData, setMyData] = useState({});

  async function fetchMyItems() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/my-items`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setMyData(data)
    }
    catch (error) {
      setMyData({})
    }
  }

  useEffect(() => {
    fetchMyItems()
  }, []);

  const archiveProduct = async (product_id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${product_id}/add-to-archive`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) {
        throw data.error
      }
      const data = await response.json()
      if (data.status === 'archived') {
        let updated_products = myData.products.filter(p => p.id !== product_id)
        setMyData(previousState => {
          return { ...previousState, products: updated_products }
        })
        MySwal.fire({
          title: 'Product Archived Successfully'
        })
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error removing the product from Saved List'
      })
    }
  }

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-6 offset-3">
            <div className="d-flex justify-content-between">
              <h1>My Items</h1>
              <Link to="/archived-products">
                <strong className="text-orange cursor-pointer">Archived</strong>
              </Link>
            </div>
            <div>
              <strong>Last 30 days</strong>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-4">
                  <div className="d-flex flex-column">
                    <strong className="fs-1">{myData?.posts}</strong>
                    <span>Posts</span>
                  </div>
                  <div className="d-flex flex-column">
                    <strong className="fs-1">{myData.views}</strong>
                    <span>Views</span>
                  </div>
                  <div className="d-flex flex-column">
                    <strong className="fs-1">{myData.sold}</strong>
                    <span>Sold</span>
                  </div>
                </div>
                <div>
                  {/* <button className="btn btn-success bg-orange rounded-pill">Insight</button> */}
                </div>
              </div>
            </div>
            <ul className="list-group list-group-flush">
              {
                myData.products?.length === 0 ? <div><h2>No Products in My Items List</h2></div> : myData.products?.map(product => {
                  return <li className="list-group-item d-flex justify-content-between align-items-center" key={product.id}>
                    <div className="d-flex align-items-center">
                      <Link to={`/product/${product.product_id}`}>
                        <img src={product.main_image} style={{ height: '50px', width: '50px' }} />
                      </Link>
                      <div className="d-flex flex-column ms-3">
                        <Link to={`/product/${product.product_id}`}><strong className="underline fs-5">{product.title}</strong></Link>
                        <strong className="fs-6 cursor-pointer" onClick={() => archiveProduct(product.id)}>Archive</strong>
                      </div>
                    </div>
                    <Link to={`/product/${product.id}`}><img src={rightArrow} style={{ height: '16px', width: '16px' }} /></Link>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
      </div>
      <Footer classNames={'footer'} />
    </>
  )
};


export default MyItems;