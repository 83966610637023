
import { useRef, useState } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

function UpdatePhone({ onHide, setUser, onChangeContent }) {
  const formRef = useRef();
  const [error, setError] = useState(null);

  const updateData = async (userInfo) => {
    onChangeContent('verify_phone');
    // const url=`${process.env.REACT_APP_BACKEND_URL}/users/update-phone`
    // try{
    //     const response=await fetch(url, {
    //         method: "post",
    //         headers: {
    //             'content-type': 'application/json',
    //             'accept': 'application/json',
    //             "Authorization": localStorage.getItem("token")
    //         },
    //         body: JSON.stringify(userInfo)
    //     })
    //     const data=await response.json()
    //     if(!response.ok){
    //       // setError(data.message)
    //       throw data.message
    //     }
    //     onHide();
    //     onChangeContent('verify_phone');
    //     MySwal.fire({
    //       title: data.message
    //     })
    // }catch(error){
    //    setError(error)
    // }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { phone: data.phone }
    }
    updateData(userInfo)
    e.target.reset()
  }

  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-2">
          <label className="fw-medium">Phone(e.g: +5511912345678)</label>
          <input type="text" name="phone" className="form-control w-100" />
          <span className="feedback"></span>
        </div>

        <div className="actions mt-3">
          <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative text-white fw-medium">Update Phone</button>
        </div>
      </form>
    </>
  );
}

export default UpdatePhone;