
import { useRef, useState } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import OfferupModal from "../Modals/OfferupModal";

const MySwal = withReactContent(Swal);

function MakeOffer({ onHide, product }) {
  const formRef = useRef();
  const [error, setError] = useState(null);
  const [value, setValue] = useState(`$${product.price}`);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (/^\$?[0-9]*/.test(newValue)) {
      setValue(newValue);
    }
  };

  const submitOffer = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    const newData = {
      offer_price: data.offer_price,
      product_id: data.product_id
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/messages/make-offer`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(newData)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      onHide();
      Swal.fire({
        title: "Offer Sent"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Sending Offer"
      })
    }
  }

  return (
    <>
      <form className="d-flex flex-column align-items-center" onSubmit={submitOffer}>
        <input type="text" className="form-control" value={value} name="offer_price" onChange={handleChange} style={{ fontSize: '4rem', width: '70%', textAlign: 'center', padding: '0px', fontWeight: 'bolder', borderRadius: '8px' }} />
        <input type="hidden" name="product_id" value={product.id} />
        <button type="submit" className='btn btn-outline-light border border-1 w-100 bg-orange position-relative text-white fw-medium mt-3 p-2'>Make Offer</button>
      </form>
    </>
  );
}

export default MakeOffer;