
import { useEffect, useState, useRef } from "react";
import { Link, redirect } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Footer from "../Footer";
import { useParams } from "react-router-dom";

const MySwal = withReactContent(Swal);

const EditUser = () => {
  const zipRef = useRef();
  const [user, setUser] = useState({});
  // const [currentLocation, setCurrentLocation] = useState(null);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loader, showLoader] = useState(false);

  if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
    // need to check the real use from database
    window.location.href = "/";
  }

  const getLoggedInUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/get-user`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      return data;
    }
    catch (error) {
      return {};
    }
  }

  function setData(key, value) {
    setUser(p => {
      let updated_user = { ...p }
      updated_user[key] = value
      return updated_user;
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // var form_data = new FormData();
    let validProduct = true;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/users/${id}`, {
        method: "put",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(user)
      })
      if (!response.ok) throw Error
      MySwal.fire({
        title: 'User Updated Successfully'
      })
    }
    catch (error) {
    }
  }

  const getUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/users/${id}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
    }
    catch (error) {
      setUser(null);
    }
  }



  useEffect(() => {
    getLoggedInUser();
    getUser();
  }, [id])



  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="container">
          <div className="row">
            <div className="col-6 offset-3">
              <div className="card">
                <div className="card-header">
                  <h1>Edit User</h1>
                </div>
                <div className="card-body">
                  <div className="form-group mt-2">
                    <label className="fw-bold">Name</label>
                    <input type="text" className="form-control" name="product[title]" value={user.name} onChange={(event) => setData('name', event.target.value)} />
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Email</label>
                    <textarea type="text" className="form-control" name="product[description]" value={user.email} onChange={(event) => setData('email', event.target.value)} />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <button className="btn btn-success mt-2">Submit</button>
                <Link className="btn bg-orange text-white" to={"/admin/users"}>Go Back</Link>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  )
}
export default EditUser;