import { useEffect, useState, useRef, useContext } from 'react'
import userAvatar from './../../../assets/images/user_avatar.svg'
import linkIcon from './../../../assets/images/external-link.svg'
import OfferupModal from '../../Modals/OfferupModal';
import UpdateName from './UpdateName';
import UpdateEmail from './UpdateEmail';
import UpdateLocation from './UpdateLocation';
import UpdatePhone from './updatePhone';
import VerifyPhone from '../../Auth/VerifyPhone';
import VerifyOtp from '../../Auth/VerifyOtp';
import ForgotPassword from '../../Auth/ForgotPassword';
import ThemeContext from '../../../context/ThemeContext';
import { Link } from 'react-router-dom';
import Settings from '../../Notification/settings';
import Footer from '../../Footer';

const NotificationSettings = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [imageURL, setImageUrl] = useState(userAvatar);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState('');
  const imgInputRef = useRef();
  const ImgformRef = useRef();
  // const [theme, setTheme] = useState(localStorage.getItem('theme') || "light");
  let showGobackBtn = false;
  let modalTitle = '';
  let goBackContent = '';
  let modalContent = '';

  modalTitle = content.title;
  modalContent = <Settings description={content.description} user={user} setUser={setUser} field_name={content.field_name} showEmail={content.showEmail} showPush={content.showPush} />
  // if(content === "changeName") {
  //   modalTitle = "Update Name"
  //   modalContent = <UpdateName onHide={hideModal} setUser={setUser} />
  // }else if(content === "changeEmail"){
  //   modalTitle = "Update Email"
  //   modalContent = <UpdateEmail onHide={hideModal} setUser={setUser} />
  // }else if(content === "changeLocation"){
  //   modalTitle = "Update Location"
  //   modalContent = <UpdateLocation onHide={hideModal} setUser={setUser} />
  // }else if(content === "changePhone") {
  //   // onChangeContent('verify_phone');
  //   modalTitle = "Update Phone"
  //   modalContent = <VerifyPhone onChangeContent={handleSetContent} onHide={hideModal} />
  // }else if (content === "verify_otp") {
  //   modalTitle = 'Code Validation';
  //   modalContent = <VerifyOtp onChangeContent={handleSetContent} onHide={hideModal} setCurrUser={setUser} />
  // }else if(content === "changePassword") {
  //   modalTitle = 'Forgot Password';
  //   modalContent = <ForgotPassword onHide={hideModal} />
  // }

  const getText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/settings`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
      if (data.avatar_url) {
        setImageUrl(data.avatar_url)
      }
    }
    catch (error) {
      // setUser(null);
    }
  }

  const updateAvatar = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('user[avatar]', image)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/update-avatar`, {
        method: "put",
        headers: {
          // "content-type": "application/json", //commenting this is important when submitting form with image
          "authorization": localStorage.getItem("token")
        },
        body: formData
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
    }
    catch (error) {
      // setUser(null);
    }
  }

  useEffect(() => {
    getText()
  }, [])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  }

  function hideModal() {
    setShowModal(false);
    setContent('');
  }

  function handleSetContent(title, description, field_name, showEmail, showPush) {
    setContent({ title: title, description: description, field_name: field_name, showEmail: showEmail, showPush: showPush })
    setShowModal(true);
  }

  function changeTheme(theme) {
    setTheme(theme);
    localStorage.setItem('theme', theme);
  }

  return (
    <>
      {showModal &&
        <OfferupModal
          showGoBack={showGobackBtn}
          show={showModal}
          hide={hideModal}
          title={modalTitle}
          goBackContent={goBackContent}
          onChangeContent={handleSetContent}
        >
          {modalContent}
        </OfferupModal>
      }
      <div className="container mt-3">
        <div className='d-flex gap-2 mt-4 mt-lg-0'>
          <Link to={"/"}>Home</Link>
          <span>›</span>
          <Link to={"/account-settings"}>Account</Link>
          <span>›</span>
          <Link to={"/account-settings"}>Account Settings</Link>
          <span>›</span>
          <span>Manage Notifications</span>
        </div>
        <div className="row mt-3">
          <div className='col-3 d-none d-lg-block border py-3' style={{ padding: 0 }}>
            <h2 className='mx-3'>Account</h2>
            {/* <h5 className='mx-3'>Transaction</h5> */}
            {/* <ul className='list-group' style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/account/transactions"} style={{ textDecoration: 'none', color: 'black' }}>Purchases & Sales</Link>
              </li>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/account/payments"} style={{ textDecoration: 'none', color: 'black' }}>Payment & Deposit methods</Link>
              </li>
            </ul> */}
            <h5 className='mx-3'>Saves</h5>
            <ul className='list-group' style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/saved_products"} style={{ textDecoration: 'none', color: 'black' }}>Saved Items</Link>
              </li>
            </ul>
            <h5 className='mx-3'>Account</h5>
            <ul className='list-group' style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/account-settings"} style={{ textDecoration: 'none', color: 'black' }}>Account Settings</Link>
              </li>
            </ul>
            <div className='mx-3'>
              <Link to={`/user/${user?.id}`} style={{ textDecoration: 'underline', color: 'black', display: 'flex', alignItems: 'center' }}>
                <span>View public profile</span>
                <img src={linkIcon} alt='link' style={{ height: '20px', width: '20px' }} />
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <h1>Buying & Sellings</h1>
            <p>Important updates about items you are buying or selling</p>
            <ul className="list-group list-group-flush">
              <li className={`list-group-item d-flex justify-content-between ${theme === "dark" ? "bg-dark text-white" : ""}`}>
                <span>Chat Messages</span>
                <strong className="text-orange cursor-pointer" onClick={() => handleSetContent('Chat Messages', 'Alerts you when there are new messages about items you are buying and selling', 'buy_sell_chat_msgs', false, true)}>Edit</strong>
              </li>
              {/* <li className={`list-group-item d-flex justify-content-between ${theme === "dark" ? "bg-dark text-white" : ""}`}>
                <span>Item Updates</span>
                <strong className="text-orange cursor-pointer" onClick={() => handleSetContent('Item Updates', 'Alerts about items you have viewed', 'buy_sell_item_update', true, false)}>Edit</strong>
              </li> */}
            </ul>

            <h1>Buying & Sellings: Alerts</h1>
            <p>Important updates and recommendations about items, deals, and new followers</p>
            <ul className="list-group list-group-flush">
              <li className={`list-group-item d-flex justify-content-between ${theme === "dark" ? "bg-dark text-white" : ""}`}>
                <span>Personalized Recommendations</span>
                <strong className="text-orange cursor-pointer" onClick={() => handleSetContent('Personalized<br/>Recommendations', 'Recommendations for items based on your interests and activity', 'buy_sell_personal_recommendations', true, false)}>Edit</strong>
              </li>
              {/* <li className={`list-group-item d-flex justify-content-between ${theme === "dark" ? "bg-dark text-white" : ""}`}>
                <span>Follows</span>
                <strong className="text-orange cursor-pointer" onClick={() => handleSetContent('Follows', 'Alerts about new followers to your account or new items from people you follow', 'buy_sell_follows', true, false)}>Edit</strong>
              </li> */}
              {/* <li className={`list-group-item d-flex justify-content-between ${theme === "dark" ? "bg-dark text-white" : ""}`}>
                <span>Search Alerts</span>
                <strong className="text-orange cursor-pointer" onClick={() => handleSetContent('Search Alerts', 'Alerts about new items from your saved searches', 'buy_sell_search_alerts', true, true)}>Edit</strong>
              </li> */}
              {/* <li className={`list-group-item d-flex justify-content-between ${theme === "dark" ? "bg-dark text-white" : ""}`}>
                <span>Deals & Special Offers</span>
                <strong className="text-orange cursor-pointer" onClick={() => handleSetContent('Deals & Special Offers', 'Alerts about exciting seasonal items and deals', 'buy_sell_deals', true, true)}>Edit</strong>
              </li> */}
            </ul>

            {/* <h1>Account</h1>
            <p>Important updates about changes to your account</p>
            <ul className="list-group list-group-flush">
              <li className={`list-group-item d-flex justify-content-between ${theme === "dark" ? "bg-dark text-white" : ""}`}>
                <span>Orders & Sales Updates</span>
                <strong className="text-orange cursor-pointer" onClick={() => handleSetContent('Orders & Sales Updates', 'These communications include updates about ratings, seller stats, and receipts. We highly recommend keeping this enabled so OfferUp can contact you.', 'order_sale_updates', true, true)}>Edit</strong>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NotificationSettings;