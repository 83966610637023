import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';


const ListProducts = () => {

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [params, setParams] = useSearchParams();

  async function getProducts(page) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/admin/products?page=${page}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setProducts(data.products);
      setCurrentPage(data.current_page);
      setTotalPages(data.total_pages);
    } catch (error) {
      //  setError(error)
    }
  }

  useEffect(() => {
    getProducts(1);
  }, []);

  async function deleteProduct(id) {
    if (!window.confirm('Are you sure you want to delete ?')) {
      return;
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/admin/products/${id}`
    try {
      const response = await fetch(url, {
        method: "delete",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setProducts((previousProducts) => {
        let filteredProducts = previousProducts.filter(product => product.id !== data.product.id)
        return filteredProducts;
      });
    } catch (error) {
      //  setError(error)
    }
  }



  return (
    <>
      <div className="mx-3">
        <div className="d-flex gap-2 justify-content-center mt-3">
          <Link to={"/admin/products"}>
            <button className="btn btn-sm btn-primary">Manage Products</button>
          </Link>
          <Link to={"/admin/users"}>
            <button className="btn btn-sm btn-primary">Manage Users</button>
          </Link>
          <Link to={"/admin/reports"}>
            <button className="btn btn-sm btn-primary">Reports</button>
          </Link>
        </div>
        <h1>Products</h1>
        <table className="table table-bordered">
          <thead className="bg-dark text-white">
            <th>Title</th>
            <th>Category</th>
            <th>Status</th>
            <th>Photo</th>
            <th>Actions</th>
          </thead>
          <tbody>
            {
              products && products.map(product => {
                return <tr className="border">
                  <td>{product.title}</td>
                  <td>{product.category}</td>
                  <td>{product.status}</td>
                  <td><img src={product.photo} alt={product.title} height={100} width={100} /></td>
                  <td>
                    <Link to={`/product/${product.id}`}>
                      <button className="btn btn-primary">View</button>
                    </Link>
                    <Link to={`/admin/products/${product.id}/edit`}>
                      <button className="btn btn-success mx-2">Edit</button>
                    </Link>
                    <button className="btn btn-danger" onClick={() => deleteProduct(product.id)}>Delete</button>
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
        <div className="d-flex justify-content-center">
          {
            currentPage > 1 &&
            <button className="btn btn-primary btn-sm me-2" onClick={() => getProducts(currentPage - 1)}>Previous Page</button>
          }
          {
            currentPage < totalPages &&
            <button className="btn btn-primary btn-sm" onClick={() => getProducts(currentPage + 1)}>Next Page</button>
          }
        </div>
      </div>
    </>
  )
};


export default ListProducts;