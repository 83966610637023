import { useState, useEffect, useRef } from 'react';
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "../App.css";
import ListProducts from './Product/ListProducts.jsx';
import Sidebar from './Sidebar.jsx';
import { useSearchParams } from 'react-router-dom';
import Banner from './Product/Banner.jsx';
import SearchIcon from '../assets/images/search.svg'


const Home = ({ min, max, hasMore, products, getLatLngProducts, searchValue }) => {
  const [params, setParams] = useSearchParams();
  const searchRef = useRef();

  // useEffect(() => {
  //   console.log("Home Page rendered");
  //   getLatLngProducts();
  // }, [timestamp])

  // const getLatLngProducts = () => {
  //   const fetchNearestProducts = async (latitude, longitude) => {
  //     try {
  //       let queryStr = `?min=${min}&max=${max}&condition=${condition}&lat=${latitude}&lng=${longitude}&search=${searchValue}&page=${page}`;
  //       const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products${queryStr}`, {
  //         method: "get",
  //         headers: {
  //           "content-type": "application/json",
  //           // "authorization": localStorage.getItem("token")
  //         }
  //       })
  //       if (!response.ok) throw Error
  //       const data = await response.json()
  //       // setProducts(data)
  //       setProducts((prevProducts) => [...prevProducts, ...data.products]);
  //       setPage((prevPage) => prevPage + 1);
  //       setHasMore(data.current_page < data.total_pages);
  //     }
  //     catch (error) {
  //       setProducts([])
  //     }
  //   }

  //   const getCurrentLocation = () => {
  //     fetchNearestProducts(lat, lng)
  //     // showLoader(true)
  //     // let lat = localStorage.getItem("lat");
  //     // let lng = localStorage.getItem("lng");
  //     //if (lat != null && lng != null) {
  //     //  fetchNearestProducts(lat, lng);
  //     //} else {
  //     //  fetchNearestProducts()
  //     // if (navigator.geolocation) {
  //     //   navigator.geolocation.getCurrentPosition(
  //     //     (position) => {
  //     //       const latitude = position.coords.latitude;
  //     //       const longitude = position.coords.longitude;
  //     //       localStorage.setItem("lat", latitude);
  //     //       localStorage.setItem("lng", longitude);
  //     //       // setCurrentLocation({ latitude, longitude });
  //     //       fetchNearestProducts(latitude, longitude);
  //     //     },
  //     //     (error) => {
  //     //     }
  //     //   );
  //     // } else {
  //     // }
  //     //}
  //   }

  //   // getCurrentLocation();
  //   // setTimeout(() => {
  //   //   fetchNearestProducts(lat, lng);
  //   // }, 200);
  // }

  // const getProducts=async ()=>{
  //   try {
  //     // navigator.geolocation.getCurrentPosition((position) => {

  //     // })
  //     let queryStr = `?min=${min}&max=${max}&condition=${condition}`;
  //     const response=await fetch(`${process.env.REACT_APP_BACKEND_URL}/products${queryStr}`, {
  //         method: "get",
  //         headers: {
  //             "content-type": "application/json",
  //             // "authorization": localStorage.getItem("token")
  //         }
  //     })
  //     if(!response.ok) throw Error
  //     const data=await response.json()
  //     setProducts(data)
  //   }
  //   catch(error){
  //     setProducts([])
  //   }
  // }

  function submitSearchForm(event) {
    event.preventDefault();
    let cParams = []
    if (params.get("min")) {
      cParams.push({ key: "min", value: params.get("min") })
    }
    if (params.get("max")) {
      cParams.push({ key: "max", value: params.get("max") })
    }
    if (params.get("condition")) {
      cParams.push({ key: "condition", value: params.get("condition") })
    }

    if (params.get("category_id")) {
      cParams.push({ key: "category_id", value: params.get("category_id") })
    }

    if (params.get("sub_category_id")) {
      cParams.push({ key: "sub_category_id", value: params.get("sub_category_id") })
    }

    if (searchRef.current.value !== '') {
      cParams.push({ key: "search", value: searchRef.current.value })
    }

    // let paramsObj = {};
    // cParams.forEach(x => paramsObj[x.key] = x.value)
    // setParams(paramsObj)

    // window.location.reload();
    let paramsStr = [];
    cParams.forEach(x => paramsStr.push(`${x.key}=${x.value}`))
    window.location.href = `/?${paramsStr.join('&')}`
  }

  return (
    <div className='mx-2'>
      <div className='row mt-5'>
        <Sidebar min={min} max={max} />
        <div className='col-12 col-md-8 col-lg-10'>
          {/* <Banner /> */}
          <ListProducts products={products} fetchProducts={getLatLngProducts} hasMore={hasMore} />
        </div>
      </div>
    </div>
  );
}
export default Home;