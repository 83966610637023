import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import playButton from './../assets/images/play.svg'
import downArrow from './../assets/images/down-arrow.svg'


const Sidebar = ({ min, max }) => {
  const navigate = useNavigate();
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [conditionParams, setConditionParams] = useSearchParams();
  let current_params = conditionParams.get("condition")?.split(",") || []
  // const formRef = useRef()
  const filtersRef = useRef();
  const minRef = useRef();
  const maxRef = useRef();
  // const location = useLocation();


  // const submitForm = (event) => {
  //   event.preventDefault();
  //   const formData=new FormData(formRef.current)
  //       const data=Object.fromEntries(formData)
  //   navigate(`?min=${data.min}&max=${data.max}`)
  // }

  // function fetchCondition() {

  // }

  const fetchCondition = (event) => {

    let cParams = []
    if (conditionParams.get("min")) {
      cParams.push({ key: "min", value: conditionParams.get("min") })
    }

    if (conditionParams.get("max")) {
      cParams.push({ key: "max", value: conditionParams.get("max") })
    }

    if (conditionParams.get("search")) {
      cParams.push({ key: "search", value: conditionParams.get("search") })
    }

    if (conditionParams.get("category_id")) {
      cParams.push({ key: "category_id", value: conditionParams.get("category_id") })
    }

    if (conditionParams.get("sub_category_id")) {
      cParams.push({ key: "sub_category_id", value: conditionParams.get("sub_category_id") })
    }

    const { name, value } = event?.target;
    let conParam = conditionParams.get("condition")?.split(",") || []
    if (event.target.checked) {
      conParam.push(value);
    } else {
      let index = conParam.indexOf(value)
      if (index > -1) {
        conParam.splice(index, 1);
      }
    }
    cParams.push({ key: "condition", value: conParam.join(",") })

    let paramsStr = [];
    cParams.forEach(x => paramsStr.push(`${x.key}=${x.value}`))
    window.location.href = `/?${paramsStr.join('&')}`

    // let paramsObj = {};
    // cParams.forEach(x => paramsObj[x.key] = x.value)
    // setConditionParams(paramsObj)

    // window.location.reload();
  }

  function submitPriceForm(event) {
    event.preventDefault();

    let cParams = []
    cParams.push({ key: "min", value: minRef.current.value })
    cParams.push({ key: "max", value: maxRef.current.value })
    if (conditionParams.get("condition")) {
      cParams.push({ key: "condition", value: conditionParams.get("condition") })
    }
    if (conditionParams.get("category_id")) {
      cParams.push({ key: "category_id", value: conditionParams.get("category_id") })
    }
    if (conditionParams.get("sub_category_id")) {
      cParams.push({ key: "sub_category_id", value: conditionParams.get("sub_category_id") })
    }
    let paramsObj = {};
    cParams.forEach(x => paramsObj[x.key] = x.value)
    setConditionParams(paramsObj)

    window.location.reload();
  }

  function toggleFilters() {
    filtersRef.current.classList.toggle("d-none");
  }

  // const onChange=(event)=>{
  //   const {name, value} = event?.target;
  //   navigate({[name]: value})       
  // }

  return (
    <div className='col-12 col-md-4 col-lg-2 p-0 m-0 mb-2'>
      <div className='d-flex d-md-none justify-content-between p-2' style={{ background: '#e4e4e4' }} onClick={toggleFilters}>
        <strong>Filters</strong>
        <img src={downArrow} alt='down arrow' style={{ height: '20px', window: '20px' }} />
      </div>
      <div className='card border-0 d-none d-md-block filters m-2' ref={filtersRef}>
        <div className='card-body p-1 pt-3'>
          {/* <strong>{t('filterTitle')}</strong> */}
          {/* <br /> */}
          <div className='mt-2'>
            <strong>Price</strong>
          </div>
          <form onSubmit={submitPriceForm}>
            <div className='d-flex align-items-center justify-content-between'>
              <input type='number' ref={minRef} className='form-control' name='min' placeholder='Min' defaultValue={min} />
              <span className='mx-2'>-</span>
              <input type='number' ref={maxRef} className='form-control' placeholder='Max' name='max' defaultValue={max} />
              <button type='submit' className='btn btn-rounded ms-2 bg-orange'>
                <img src={playButton} alt='play button' />
              </button>
            </div>
          </form>
          <hr />
          <div>
            <strong>Condition</strong>
            <div className='d-flex mt-2'>
              <input type='checkbox' name='condition' value='new' checked={current_params.includes('new')} style={{ height: '20px', width: '20px' }} onChange={fetchCondition} />
              <div className='ms-2'>
                <span className='text-grey'>New</span>
                {/* <br />
                <span style={{ fontSize: '12px' }}>Unused, sealed box, undamaged</span> */}
              </div>
            </div>
            <div className='d-flex mt-2'>
              <input type='checkbox' name='condition' value='open_box' checked={current_params.includes('open_box')} style={{ height: '20px', width: '20px' }} onChange={fetchCondition} />
              <div className='ms-2'>
                <span className='text-grey'>Open Box</span>
                {/* <br />
                <span style={{ fontSize: '12px' }}>Unused, undamaged</span> */}
              </div>
            </div>
            <div className='d-flex mt-2'>
              <input type='checkbox' name='condition' value='reconditioned' checked={current_params.includes('reconditioned')} style={{ height: '20px', width: '20px' }} onChange={fetchCondition} />
              <div className='ms-2'>
                <span className='text-grey'>Reconditioned</span>
                {/* <br />
                <span style={{ fontSize: '12px' }}>Restored by seller or a third party</span> */}
              </div>
            </div>
            <div className='d-flex mt-2'>
              <input type='checkbox' name='condition' value='used' checked={current_params.includes('used')} style={{ height: '20px', width: '20px' }} onChange={fetchCondition} />
              <div className='ms-2'>
                <span className='text-grey'>Used</span>
                {/* <br />
                <span style={{ fontSize: '12px' }}>Works perfectly, may show some signs of wear</span> */}
              </div>
            </div>
            <div className='d-flex mt-2'>
              <input type='checkbox' name='condition' value='for_parts' checked={current_params.includes('for_parts')} style={{ height: '20px', width: '20px' }} onChange={fetchCondition} />
              <div className='ms-2'>
                <span className='text-grey'>For parts</span>
              </div>
            </div>
            <div className='d-flex mt-2'>
              <input type='checkbox' name='condition' value='others' checked={current_params.includes('others')} style={{ height: '20px', width: '20px' }} onChange={fetchCondition} />
              <div className='ms-2'>
                <span className='text-grey'>Others</span>
                {/* <br />
                <span style={{ fontSize: '12px' }}>See Item details</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Sidebar;