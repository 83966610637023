import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';


const ListProducts = ({ products, fetchProducts, hasMore }) => {
  let content = '';
  if (products.length === 0) {
    content = <h1>No Products to show</h1>
  } else {
    content = <InfiniteScroll
      dataLength={products.length}
      next={fetchProducts}
      hasMore={hasMore}
      loader={<div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      }
      // endMessage={<p>End of Products</p>}
      className={"products-list"}
    >
      {products.map((product) => (
        <div className="product-item" key={product.id}>
          <div style={{ height: 'calc(100% - 8px)' }}>
            <Link to={`/product/${product.id}`} style={{ textDecoration: 'none' }}>
              <div className="card product-shadow p-2 border-0" style={{ height: '100%' }}>
                <div className="" style={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
                  <img src={product.main_image} className="" alt={product.title} style={{ maxWidth: '100%', height: '100%', position: 'absolute', objectFit: 'contain', top: '0px' }} />
                </div>
                <div className="mx-2">
                  <p className="my-1 fs-5" style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: 'none' }}>{product.title}</p>
                  <span className="fs-5 text-dark-orange" style={{ textDecoration: 'none' }}>${product.price}</span>
                  {/* <br />
                  <strong style={{ color: "rgb(164 164 164)" }}>{product.city}</strong> */}
                </div>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </InfiniteScroll>
  }
  return (
    <div className="row">
      {
        content
      }
    </div>
  )
};


export default ListProducts;