
import { useEffect, useState, useRef } from "react";
import { redirect } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Footer from "../Footer";
import { useParams } from "react-router-dom";

const MySwal = withReactContent(Swal);

const EditProduct = () => {
  const zipRef = useRef();
  const latRef = useRef();
  const lngRef = useRef();
  const sizeRef = useRef();
  const [categories, setCategories] = useState([])
  const [sub_categories, setSubCategories] = useState([])
  const [product, setProduct] = useState({});
  const [photos, setPhotos] = useState([]);
  // const [currentLocation, setCurrentLocation] = useState(null);
  const { id } = useParams();
  const [LatLang, setLatLng] = useState({})
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null);
  const [loader, showLoader] = useState(false);

  if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
    // need to check the real use from database
    window.location.href = "/";
  }

  const getUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/get-user`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      return data;
    }
    catch (error) {
      return {};
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/categories`, {
        method: "get",
        headers: {
          "content-type": "application/json"
        }
      })

      if (!response.ok) throw Error
      const data = await response.json()
      setCategories(data)
    }

    catch (error) {
      setCategories([])
    }
  }

  const getSubCategories = async (event) => {
    let id = event.target.value;
    if (['Clothing, Shoes &amp; Accessories', 'Baby &amp; Kids', 'Sports &amp; Ourdoors'].indexOf(document.querySelector(`option[value='${id}']`).innerHTML) > -1) {
      sizeRef.current.classList.remove('d-none');
    } else {
      sizeRef.current.classList.add('d-none');
    }
    setData('category_id', id);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sub_categories?category=` + id, {
        method: "get",
        headers: {
          "content-type": "application/json"
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setSubCategories(data.sub_categories)
    }
    catch (error) {
      setSubCategories([])
    }
  }

  function setData(key, value) {
    setProduct(p => {
      let updated_product = { ...p }
      updated_product[key] = value
      return updated_product;
    })
  }

  function setFiles(event) {
    // const chosenFiles = Array.prototype.slice.call(event.target.files)
    // const uploaded = [];
    // chosenFiles.some((file) => {
    //     uploaded.push(file);
    // })

    if (event.target.files.length > 5) {
      event.target.value = null;
      MySwal.fire({
        title: 'Please select 5 or less images'
      })
      return;
    }
    setProduct(p => {
      let updated_product = { ...p }
      updated_product['photos'] = [...event.target.files]
      return updated_product;
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // var form_data = new FormData();
    let validProduct = true;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/products/${id}`, {
        method: "put",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(product)
      })
      if (!response.ok) throw Error
      MySwal.fire({
        title: 'Product Updated Successfully'
      })
    }
    catch (error) {
    }
  }

  const getProduct = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/products/${id}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setProduct(data)
    }
    catch (error) {
      setProduct(null);
    }
  }



  useEffect(() => {
    getCategories();
    getProduct();
  }, [id])

  const getLocationByZipCode = () => {
    showLoader(true);
    let zip = zipRef.current.value;
    const fetchNearestAddress = async (zip) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-zip-code?zip=${zip}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location by zip code, please check details and try again'
          })
          showLoader(false);
          return;
        }
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        setAddress(data);
        setData('lat', data.latitude);
        setData('lng', data.longitude);
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    fetchNearestAddress(zip);
  }

  const getLatLngLocation = () => {
    const fetchNearestAddress = async (latitude, longitude) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-lat-lng?lat=${latitude}&lng=${longitude}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location, please refresh and try again'
          })
          showLoader(false);
          return;
        }
        setAddress(data);
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        setData('lat', data.latitude);
        setData('lng', data.longitude);
        setData('city', data.cidade.nome)
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    const getCurrentLocation = () => {
      showLoader(true)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            // setCurrentLocation({ latitude, longitude });
            fetchNearestAddress(latitude, longitude);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    }

    getCurrentLocation();
  }


  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="container">
          <div className="row">
            <div className="col-6 offset-3">
              <h1>Sell Item</h1>
              <div className="card">
                <div className="card-header">
                  <h2>Step 1: Post</h2>
                </div>
                <div className="card-body">
                  <div className="form-group mt-2">
                    <label className="fw-bold">Title</label>
                    <input type="text" className="form-control" name="product[title]" value={product.title} onChange={(event) => setData('title', event.target.value)} />
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Description(optional)</label>
                    <textarea type="text" className="form-control" name="product[description]" value={product.description} onChange={(event) => setData('description', event.target.value)} />
                  </div>
                  {/* <div className="form-group">
                    <label className="fw-bold">Select Photos</label>
                    <input type="file" className="form-control" name="product[photos]" onChange={(event) => setFiles(event)} multiple />
                  </div> */}
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-header">
                  <h2>Step 2: Details</h2>
                </div>
                <div className="card-body">
                  <div className="form-group mt-2">
                    <label className="fw-bold">Category</label>
                    <select className="form-control" name="product[category_id]" onChange={getSubCategories}>
                      <option value="">Select Category</option>
                      {
                        categories.map(category => <option value={category.id} key={category.id} selected={category.id === product.category_id}>{category.name}</option>)
                      }
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Sub Category(optional)</label>
                    <select className="form-control" name="product[sub_category_id]" onChange={(event) => setData('sub_category_id', event.target.value)}>
                      <option value="">Select Sub Category</option>
                      {
                        sub_categories.map(category => <option value={category.id} key={category.id} selected={category.id === product.sub_category_id}>{category.name}</option>)
                      }
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Condition</label>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault1" value="new" checked={product.condition === "new"} onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        New (never used)
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault2" checked={product.condition === "reconditioned"} value="reconditioned" onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Reconditioned/Certified
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault3" value="open_box" checked={product.condition === "open_box"} onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault3">
                        Open Box (never used)
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault4" value="used" checked={product.condition === "used"} onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault4">
                        Used (normal wear)
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault5" value="other" checked={product.condition === "other"} onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault5">
                        Other
                      </label>
                    </div>
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Brand</label>
                    <input type="text" className="form-control" name="product[brand]" value={product.brand} onChange={(event) => setData('brand', event.target.value)} />
                  </div>
                  <div className="form-group mt-2" ref={sizeRef}>
                    <label className="fw-bold">Size Type</label>
                    <input type="text" className="form-control" name="product[size_type]" value={product.size_type} onChange={(event) => setData('size_type', event.target.value)} />
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-header">
                  <h2>Step 3: Price</h2>
                </div>
                <div className="card-body">
                  <div className="form-group mt-2">
                    <label className="fw-bold">Price</label>
                    <input type="number" className="form-control" value={product.price} name="product[price]" placeholder="$0" onChange={(event) => setData('price', event.target.value)} />
                  </div>
                </div>
              </div>
              <button className="btn btn-success mt-2">Submit</button>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  )
}
export default EditProduct;