
import { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import { useSearchParams } from 'react-router-dom';
import RightArrow from "../assets/images/right-arrow.svg"

const NavbarCategories = ({ categories }) => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [params, setParams] = useSearchParams();

  function searchByCategory(category_id, sub_category_id) {
    let cParams = []
    if (params.get("min")) {
      cParams.push({ key: "min", value: params.get("min") })
    }
    if (params.get("max")) {
      cParams.push({ key: "max", value: params.get("max") })
    }
    if (params.get("condition")) {
      cParams.push({ key: "condition", value: params.get("condition") })
    }
    cParams.push({ key: "category_id", value: category_id })
    cParams.push({ key: "sub_category_id", value: sub_category_id })
    let paramsObj = {};
    cParams.forEach(x => paramsObj[x.key] = x.value)
    setParams(paramsObj)

    window.location.reload();
  }

  function toggleSubcategories(indx) {
    document.getElementsByClassName("sub-list")[indx].classList.toggle("d-none")
  }


  return (
    <div className="mx-3">
      <strong>Categories</strong>
      <div className={`d-flex flex-column justify-content-between`}>
        {categories && categories.map((category, indx) => {
          return (
            <div key={category.name} className="d-flex justify-content-between" onClick={() => toggleSubcategories(indx)}>
              <div style={{ maxWidth: '224px' }} className="offer-body-2" key={category.id}>
                <div className="py-8" style={{ fontSize: '14px', textAlign: 'left' }}>
                  <strong>{category.name}</strong>
                </div>
                <div className="ms-2 sub-list d-none">
                  <div className={`${theme === "dark" ? "bg-dark text-light" : ""}`} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', gap: '5px', padding: '0px 5px' }}>
                    {category &&
                      category.sub_categories.map((sub_category) => {
                        return (
                          <div onClick={() => searchByCategory(category.id, sub_category.id)} className="item" key={sub_category.id}>
                            {sub_category.name}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <img src={RightArrow} alt="right arrow" style={{ height: "15px", width: '15px' }} />
            </div>
          )
        })
        }
      </div>
    </div >
  )
};


export default NavbarCategories;