import { Link } from "react-router-dom";
import twitterIcon from '../assets/images/twitter.svg'
import facebookIcon from '../assets/images/facebook.svg'
import instagramIcon from '../assets/images/instagram.svg'
import linkedInIcon from '../assets/images/linkedin.svg'

const Footer = ({ classNames }) => {
  // return <footer className={`background-green text-white py-3 mt-3 ${classNames}`}>
  //   <div className="row px-5">
  //     <div className="col-10">
  //       <div className="row">
  //         <div className="col-3">
  //           <div className="d-flex flex-column">
  //             <strong>Shop</strong>
  //             <Link to="#" className="text-white fs-7">How it works</Link>
  //             <Link to="#" className="text-white fs-7">Explore</Link>
  //             <Link to="#" className="text-white fs-7">Trust & Safety</Link>
  //             <Link to="#" className="text-white fs-7">Safe trade spots</Link>
  //           </div>
  //         </div>
  //         <div className="col-3">
  //           <div className="d-flex flex-column">
  //             <strong>Sell</strong>
  //             <Link to="#" className="text-white fs-7">Post and item</Link>
  //             <Link to="#" className="text-white fs-7">Join OfferUp Business</Link>
  //             <Link to="#" className="text-white fs-7">Auto dealerships</Link>
  //           </div>
  //         </div>
  //         <div className="col-3">
  //           <div className="d-flex flex-column">
  //             <strong>About</strong>
  //             <Link to="#" className="text-white fs-7">Our story</Link>
  //             <Link to="#" className="text-white fs-7">Careers</Link>
  //             <Link to="#" className="text-white fs-7">Press</Link>
  //           </div>
  //         </div>
  //         <div className="col-3">
  //           <div className="d-flex flex-column">
  //             <strong>Help</strong>
  //             <Link to="#" className="text-white fs-7">Help center</Link>
  //             <Link to="#" className="text-white fs-7">Community</Link>
  //             <Link to="#" className="text-white fs-7">Blog</Link>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="col-2">
  //       <button className="btn btn-outline-light border border-1 bg-orange rounded-pill position-relative">Get the app</button>
  //       <div className="d-flex gap-2 mt-2">
  //         <Link to="https://twitter.com" target="_blank"><img src={twitterIcon} style={{ height: '20px', width: '20px' }} /></Link>
  //         <Link to="https://facebook.com" target="_blank"><img src={facebookIcon} style={{ height: '20px', width: '20px' }} /></Link>
  //         <Link to="https://instragra.com" target="_blank"><img src={instagramIcon} style={{ height: '20px', width: '20px' }} /></Link>
  //         <Link to="https://linkedin.com" target="_blank"><img src={linkedInIcon} style={{ height: '20px', width: '20px' }} /></Link>
  //       </div>
  //     </div>
  //   </div>
  //   <hr />
  //   <div className="px-5">
  //     <div className="d-flex justify-content-between">
  //       <div>&copy; 2024 OfferUp Inc.</div>
  //       <div className="d-flex gap-1" style={{ alignItems: 'baseline' }}>
  //         <Link to="https://twitter.com" target="_blank" className="text-white fs-7">Terms oof Service</Link>
  //         <div>/</div>
  //         <Link to="https://twitter.com" target="_blank" className="text-white fs-7">Privacy Policy</Link>
  //         <div>/</div>
  //         <Link to="https://twitter.com" target="_blank" className="text-white fs-7">Do Not Sell or Share My Personal Information</Link>
  //       </div>
  //     </div>
  //   </div>
  // </footer>
}

export default Footer;