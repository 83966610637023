import { useEffect, useState, useRef, useContext } from 'react'
import userAvatar from './../../../assets/images/user_avatar.svg'
import linkIcon from './../../../assets/images/external-link.svg'
import OfferupModal from '../../Modals/OfferupModal';
import UpdateName from './UpdateName';
import UpdateEmail from './UpdateEmail';
import UpdateLocation from './UpdateLocation';
import UpdatePhone from './updatePhone';
import VerifyPhone from '../../Auth/VerifyPhone';
import VerifyOtp from '../../Auth/VerifyOtp';
import ForgotPassword from '../../Auth/ForgotPassword';
import ThemeContext from '../../../context/ThemeContext';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import moment from 'moment/moment';
import withReactContent from 'sweetalert2-react-content';
import InfiniteScroll from 'react-infinite-scroll-component';
import cable from '../../../cable';
import Autocomplete from "react-google-autocomplete";
import starIcon from './../../../assets/images/star-grey.svg'
import starIconFilled from './../../../assets/images/star-golden.svg'
// import { ReactSVG } from 'react-svg'
// import PlacesAutocomplete from 'react-places-autocomplete';
import Parser from 'html-react-parser';
import Footer from '../../Footer';

const MySwal = withReactContent(Swal);

const Messages = ({ currUser }) => {
  const msgRef = useRef();
  const msgContainerRef = useRef();
  const { id } = useParams();
  const [inbox, setInbox] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [user, setUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [messageLoader, setMessageLoader] = useState(true);
  const [notificationLoader, setNotificationLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [messages, setMessages] = useState([]);
  const [place, setPlace] = useState('');

  const [offerModal, setOfferModal] = useState(false);
  const [meetupModal, showMeetupModal] = useState(false);
  const [offerBody, setOfferBody] = useState('');
  const [offerMessage, setOfferMessage] = useState('');

  const { theme, setTheme } = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [imageURL, setImageUrl] = useState(userAvatar);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState('');
  const [product_sold, set_product_sold] = useState(false);
  const [product_received, set_product_received] = useState(false);
  const [payment_received, set_payment_received] = useState(false);
  const [ratingModal, showRatingModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [rated, setRated] = useState(false);
  const imgInputRef = useRef();
  const ImgformRef = useRef();
  const feedbackRef = useRef();
  // const [theme, setTheme] = useState(localStorage.getItem('theme') || "light");
  let showGobackBtn = false;
  let modalTitle = '';
  let goBackContent = '';
  let modalContent = '';

  if (content === "changeName") {
    modalTitle = "Update Name"
    modalContent = <UpdateName onHide={hideModal} setUser={setUser} />
  } else if (content === "changeEmail") {
    modalTitle = "Update Email"
    modalContent = <UpdateEmail onHide={hideModal} setUser={setUser} />
  } else if (content === "changeLocation") {
    modalTitle = "Update Location"
    modalContent = <UpdateLocation onHide={hideModal} setUser={setUser} />
  } else if (content === "changePhone") {
    // onChangeContent('verify_phone');
    modalTitle = "Update Phone"
    modalContent = <VerifyPhone onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === "verify_otp") {
    modalTitle = 'Code Validation';
    modalContent = <VerifyOtp onChangeContent={handleSetContent} onHide={hideModal} setCurrUser={setUser} />
  } else if (content === "changePassword") {
    modalTitle = 'Forgot Password';
    modalContent = <ForgotPassword onHide={hideModal} />
  }

  const getInbox = async (id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/inboxes/${id}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setRated(data.product.rated);
      setInbox(data);
      set_product_sold(data.product.sold)
      set_product_received(data.product.received)
      set_payment_received(data.product.payment_received)
      // setMessages(data.messages);
      // setPage(2);
      // setHasMore(true);
      // fetchMessages(id);
      setCurrImage(data.product.photos[0]);
    } catch (error) {
      //  setError(error)
    }
  }

  const fetchMessages = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/inboxes/${id}/get-messages?page=${page}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setMessages((prevMessages) => [...prevMessages, ...data.messages]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(data.current_page < data.total_pages);
      scrollToBottom()
      // setCurrImage(data.product.photos[0]);
      // scrollToBottom();
    } catch (error) {
      //  setError(error)
    }
  }

  const getText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/settings`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
      if (data.avatar_url) {
        setImageUrl(data.avatar_url)
      }
    }
    catch (error) {
      // setUser(null);
    }
  }

  function analyzeOffer(message) {
    setOfferMessage(message);
    setOfferBody(message.body);
    setOfferModal(true);
  }

  useEffect(() => {
    getText();
    getInbox(id);
    fetchMessages();
    const channel = cable.subscriptions.create(
      { channel: 'MessagesChannel', user_id: currUser?.id },
      {
        received: (message) => {
          setMessages(prevMessages => {
            if (message.message.message_type === "offer" && (message.message.status === "accepted" || message.message.status === "rejected")) {
              let indx = prevMessages.findIndex(msg => msg.id === message.message.id)
              prevMessages[indx] = message.message
              return [...prevMessages]
            } else {
              return [message.message, ...prevMessages]
            }
          })
          // setMessages(prevMessages => [...prevMessages, message]);
        }
      }
    );
    const channel2 = cable.subscriptions.create(
      { channel: 'InboxesChannel', inbox_id: id },
      {
        received: (message) => {
          if (message.sold) {
            set_product_sold(message.sold)
          }
        }
      }
    );
    return () => {
      channel.unsubscribe();
      channel2.unsubscribe();
    };
  }, [currUser, id]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  }

  function hideModal() {
    setShowModal(false);
    setContent('');
  }

  function handleSetContent(content) {
    setShowModal(true);
    setContent(content);
  }

  const scrollToBottom = () => {
    // msgContainerRef.current.scrollTo(0, msgContainerRef.current.scrollHeight)
    msgContainerRef.current.scrollTop = msgContainerRef.current.scrollHeight;
  }

  async function sendMessage(product_id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/messages`
    let formData = {
      message: msgRef.current.value,
      product_id: product_id,
      receiver_id: inbox.user_id
    }

    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(formData)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      msgRef.current.value = "";
      setMessages(prevMessages => {
        return [data.message, ...prevMessages]
      })
      // setInbox((prevData) => {
      //   prevData.messages.push(data.message);
      //   return { ...prevData, messages: prevData.messages }
      // })
      // scrollToBottom()
      // Swal.fire({
      //   title: "Message Sent"
      // })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Sending Message"
      })
    }
  }

  async function shareLocation() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/messages`
    let formData = {
      message: `<b>lets meet at this location:</b><br />
                  <a href='https://www.google.com/maps/search/?api=1&query=${place.geometry.location.lat()},${place.geometry.location.lng()}' class='btn btn-outline-light border border-1 bg-white text-orange rounded-pill position-relative mt-2' target='_blank'>Open Google Map</a>`,
      product_id: inbox.product.id,
      receiver_id: inbox.user_id,
      message_type: "location"
    }
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(formData)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      showMeetupModal(false);
      msgRef.current.value = "";
      setMessages(prevMessages => {
        return [data.message, ...prevMessages]
      })
      Swal.fire({
        title: "Location has been shared..."
      })
      // setInbox((prevData) => {
      //   prevData.messages.push(data.message);
      //   return { ...prevData, messages: prevData.messages }
      // })
      // scrollToBottom()
      // Swal.fire({
      //   title: "Message Sent"
      // })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Sharing Location"
      })
    }
  }

  async function acceptOffer(message) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/messages/${offerMessage?.id}/accept-offer`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        // body: JSON.stringify()
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setMessages(prevMessage => {
        let indx = prevMessage.findIndex(msg => msg.id === data.id);
        prevMessage[indx] = data
        return [...prevMessage]
      })
      setOfferModal(false);
      Swal.fire({
        title: "Offer Accepted"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Accepting Offer"
      })
    }
  }

  async function rejectOffer() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/messages/${offerMessage?.id}/reject-offer`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        // body: JSON.stringify()
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      setMessages(prevMessage => {
        let indx = prevMessage.findIndex(msg => msg.id === data.id);
        prevMessage[indx] = data
        return [...prevMessage]
      })
      setOfferModal(false);
      Swal.fire({
        title: "Offer Rejected"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Rejecting Offer"
      })
    }
  }

  async function productSold(message_id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${id}/mark-sold`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        // body: JSON.stringify()
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      set_product_sold(true);
      Swal.fire({
        title: "Product Marked As Sold"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Marking the product as sold"
      })
    }
  }

  async function productReceived() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${id}/received`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        // body: JSON.stringify()
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      if (data.sold) {
        set_product_sold(true);
      }
      if (data.received) {
        set_product_received(true)
      }
      Swal.fire({
        title: "Product received confirmed"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Marking the product as sold"
      })
    }
  }

  async function paymentReceived() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${id}/payment-received`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        // body: JSON.stringify()
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      set_payment_received(true);
      if (data.sold) {
        set_product_sold(true);
      }
      Swal.fire({
        title: "Payment received confirmed"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Marking the product as sold"
      })
    }
  }

  function suggestMeetUp(message) {
    showMeetupModal(true);
  }

  async function saveRating() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${id}/add-rating`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify({
          rating: rating,
          feedback: feedbackRef.current.value
        })
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      showRatingModal(false);
      setRating(0);
      feedbackRef.current.value = ''
      setRated(true);
      Swal.fire({
        title: "Thank for rating the product"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error rating the product"
      })
    }
  }

  return (
    <>
      {meetupModal &&
        <OfferupModal
          showGoBack={false}
          show={true}
          hide={() => showMeetupModal(false)}
          title={"Select Place for Meetup"}
          goBackContent={''}
          closeBtn={true}
        // onChangeContent={showProductsFilter}
        >
          <div className="card-body text-center">
            <div className=''>
              <Autocomplete
                options={{
                  types: []
                }}
                className='form-control'
                apiKey={"AIzaSyA8wPFIwoNRE2AvPsL4jGckoWiThxxIwt0"}
                onPlaceSelected={(place) => {
                  setPlace(place);
                }}
              />
              <i style={{ fontSize: '12px', color: 'red' }}>Preferably to public places such as shopping malls, hospitals, police stations or supermarkets.</i>
              <button className="btn btn-outline-light border border-1 w-100 bg-orange mt-3 rounded-pill position-relative" onClick={shareLocation}>Confirm</button>
            </div>
          </div>
        </OfferupModal>
      }
      {offerModal &&
        <OfferupModal
          showGoBack={false}
          show={true}
          hide={() => setOfferModal(false)}
          title={"Analyze Offer"}
          goBackContent={''}
          closeBtn={true}
        // onChangeContent={showProductsFilter}
        >
          <div className="card-body text-center">
            {offerBody}
            <div className='d-flex gap-2 mt-3'>
              <button className="btn btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative" onClick={acceptOffer}>Accept</button>
              <button className='btn btn-outline-light border border-1 w-100 text-orange rounded-pill position-relative' onClick={rejectOffer}>Reject</button>
            </div>
          </div>
        </OfferupModal>
      }
      {
        ratingModal &&
        <OfferupModal
          showGoBack={false}
          show={true}
          hide={() => setOfferModal(false)}
          title={"Rate Product"}
          goBackContent={''}
          closeBtn={true}
        // onChangeContent={showProductsFilter}
        >
          <div className="card-body">
            <div className='d-flex justify-content-center' style={{ gap: '20px' }}>
              <img src={rating >= 1 ? starIconFilled : starIcon} className='star' style={{ height: '50px', width: '50px' }} fill="red" alt='star' onClick={() => setRating(1)} />
              <img src={rating >= 2 ? starIconFilled : starIcon} className='star' style={{ height: '50px', width: '50px' }} alt='star' onClick={() => setRating(2)} />
              <img src={rating >= 3 ? starIconFilled : starIcon} className='star' style={{ height: '50px', width: '50px' }} alt='star' onClick={() => setRating(3)} />
              <img src={rating >= 4 ? starIconFilled : starIcon} className='star' style={{ height: '50px', width: '50px' }} alt='star' onClick={() => setRating(4)} />
              <img src={rating >= 5 ? starIconFilled : starIcon} className='star' style={{ height: '50px', width: '50px' }} alt='star' onClick={() => setRating(5)} />
            </div>
            <div className='mt-2'>
              <strong>Feedback</strong>
              <textarea className='form-control' ref={feedbackRef} rows={5}></textarea>
            </div>
            <div class="p-2 text-center"><button className='btn btn-outline-light border border-1 px-3 bg-orange rounded-pill position-relative' onClick={saveRating}>Submit</button></div>
          </div>
        </OfferupModal>
      }
      <div className="container mt-3">
        <div className='d-flex gap-2'>
          <Link to={"/"}>Home</Link>
          <span>›</span>
          <Link to={"/inbox"}>Inbox</Link>
          <span>›</span>
          <span>Message</span>
        </div>
        <div className='row flex-column-reverse flex-lg-row gap-2 gap-md-0'>
          <div className="col-12 col-md-8 m-0 p-0">
            <div className='card d-flex flex-column'>
              <div className='d-flex align-items-center gap-2 border-1 border-bottom p-3'>
                <img src={inbox?.user_avatar_url ? inbox.user_avatar_url : userAvatar} style={{ height: '50px', width: '50px' }} alt='avatar' />
                <div>
                  <strong>{inbox?.user}</strong>
                  <br />
                  <span>{inbox?.location}</span>
                </div>
              </div>
              <div>
                <div id="messages" className="border-1 border-bottom p-3" style={{ flex: 1, height: '50vh', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse', gap: '15px' }}>
                  <InfiniteScroll
                    dataLength={messages.length}
                    next={fetchMessages}
                    style={{ display: 'flex', flexDirection: 'column-reverse', gap: '10px' }}
                    inverse={true}
                    hasMore={hasMore}
                    loader={
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    }
                    // endMessage={<hr />}
                    scrollableTarget="messages"  // Ensure this matches the scrollable container ID
                    className={"row"}
                  >
                    {messages && messages.map((message) => (
                      <div
                        className={`d-flex justify-content-end flex-column ${message.user_id === user?.id ? 'align-items-end my-message' : 'align-items-start other-message'} w-100`}
                        key={message.id}
                      >
                        <div className='text-dark-grey' style={{ fontSize: '12px' }}>{moment.utc(message.date).local().format('MMM D HH:mm')}</div>
                        <div className='d-flex align-items-center gap-2'>
                          {
                            message.user_id === user?.id && message.message_type === "offer" && message.status === "accepted" &&
                            <button className='btn btn-outline-light border border-1' style={{ backgroundColor: 'rgb(248,249,250)', color: '#E89122' }}>Offer Accepted</button>
                          }
                          {
                            message.user_id === user?.id && message.message_type === "offer" && message.status === "rejected" &&
                            <button className='btn btn-outline-light border border-1' style={{ backgroundColor: 'rgb(248,249,250)', color: 'red' }}>Offer Rejected</button>
                          }
                          <div className="d-flex flex-column gap-2">
                            <button className='message-btn'>
                              <div style={{ padding: '8px 16px', maxWidth: '272px', textAlign: 'left', borderRadius: '4px' }}>
                                {Parser(message.body)}
                              </div>
                            </button>
                            {
                              message.message_type === "location" && message.user_id === user?.id &&
                              <button className="btn btn-outline-light border border-1 bg-orange rounded-pill position-relative" onClick={productReceived} disabled={product_received === true ? true : false}>I have received the product</button>
                            }
                            {
                              message.message_type === "location" && message.user_id !== user?.id &&
                              <button className="btn btn-outline-light border border-1 bg-orange rounded-pill position-relative" onClick={paymentReceived} disabled={payment_received === true ? true : false}>I have received the payment</button>
                            }
                          </div>
                          {
                            message.user_id !== user?.id && message.message_type === "offer" && message.status === "accepted" &&
                            <button className='btn btn-outline-light border border-1' style={{ backgroundColor: 'rgb(248,249,250)', color: '#E89122' }}>Offer Accepted</button>
                          }
                          {
                            message.user_id !== user?.id && message.message_type === "offer" && message.status === "rejected" &&
                            <button className='btn btn-outline-light border border-1' style={{ backgroundColor: 'rgb(248,249,250)', color: 'red' }}>Offer Rejected</button>
                          }
                          {
                            message.user_id !== user?.id && message.message_type === "offer" && message.status === "pending" &&
                            <button className='btn btn-outline-light border border-1 bg-orange rounded-pill' onClick={() => analyzeOffer(message)}>Analyze Offer</button>
                          }
                        </div>
                        {
                          message.user_id === user?.id && message.message_type === "offer" && message.status === "accepted" &&
                          <button className="btn btn-outline-light border border-1 text-orange rounded-pill position-relative mt-2" onClick={() => suggestMeetUp(message)}>
                            <span className="text-orange fw-medium">Suggest MeetUp</span>
                          </button>
                        }
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>


              </div>

              {
                product_sold === true &&
                <div class="p-2 text-center"><i><b>This Product Has been sold</b></i></div>
              }
              {
                product_sold === false &&
                <div className='d-flex align-items-center justify-content-between mt-3 px-3'>
                  <textarea className='message-input' ref={msgRef} type="text" placeholder='Message...' style={{ border: 'none', overflow: 'hidden', width: '100%' }} ></textarea>
                  <button className='btn btn-outline-light border border-1 bg-orange position-relative text-white fw-medium' onClick={() => sendMessage(inbox.product.id)}>Send</button>
                </div>
              }
            </div>
            {
              product_sold === true && inbox.product.user_id !== currUser?.id && rated === false &&
              <div class="p-2 text-center"><button className='btn btn-outline-light border border-1 px-3 bg-orange position-relative p-2' onClick={() => showRatingModal(true)}>Rate this Seller</button></div>
            }
          </div>
          <div className='col-12 col-md-4 p-0 m-0'>
            <div className="d-none d-lg-flex justify-content-center" >
              <img src={currImage} alt={inbox?.product?.title} style={{ height: '50vh', width: 'auto' }} />
            </div>
            <div className="d-none d-lg-flex align-items-center gap-3 mt-2">
              {
                inbox?.product && inbox.product.photos.map(photo => {
                  let classes = ""
                  if (photo === currImage) {
                    classes += ' active-img '
                  } else {
                    classes += ' non-active-img '
                  }
                  return <img
                    key={photo}
                    className={classes}
                    src={photo}
                    alt={inbox?.product?.title}
                    style={{ height: '50px', width: '50px', borderRadius: '10px' }}
                    onClick={() => setCurrImage(photo)}
                  />
                })
              }
            </div>
            <hr />
            <div className='mx-2'>
              <strong>{inbox?.product?.title}</strong>
              <br />
              <h2>${inbox?.product?.price}</h2>
              <br />
              <Link to={`/product/${inbox?.product?.id}`} className="btn btn-outline-light text-orange rounded-pill position-relative border-orange">See Item details</Link>
            </div>
          </div>
        </div >
      </div >
      <Footer classNames='' />
    </>
  )
}

export default Messages;