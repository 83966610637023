import React, { Component } from "react";
import Chart from "react-apexcharts";

class Charts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: props.dates
        }
      },
      series: [
        {
          name: "count",
          data: props.counts
        }
      ]
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dates !== this.props.dates || prevProps.counts !== this.props.counts) {
      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            categories: this.props.dates,
          },
        },
        series: [
          {
            name: "count",
            data: this.props.counts,
          },
        ],
      });
    }
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height={400}
            // width={100}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Charts;